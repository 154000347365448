import { Injectable } from '@angular/core';
import { GlobalComponent } from 'src/app/global-component';
import { map, Observable } from 'rxjs';
import { AuthenticationService } from './auth.service';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private apiUrl = GlobalComponent.API_URL + GlobalComponent.company;
  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  getCompanyMenu(id: string): Observable<any> {
    let headers = this.auth.getHeader();
    return this.http.post<any>(this.apiUrl + '?companyId=' + id!.toString(), null, { headers }).pipe(
      map(response => response) // Extract the data property
    );
  }


}
